import { Injectable } from '@angular/core';
import { init, Data, Picker } from 'emoji-mart';
import * as localForage from 'localforage';

import { ApiService } from './api.service';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class EmojisService {
  public customEmojis: any[];

  private defaultEmojis: any[];

  private customEmojisVersion: number;

  private isLoaded = false;

  private db: any;

  constructor(
    private authService: AuthService,
    private apiService: ApiService,
  ) {
    this.db = localForage.createInstance({
      name: 'unthreadEmojis',
    });
  }

  async loadDefaultEmojis() {
    this.defaultEmojis = await this.db.getItem('defaultEmojis');

    if (!this.defaultEmojis) {
      const response = await fetch('https://cdn.jsdelivr.net/npm/@emoji-mart/data');

      this.defaultEmojis = await response.json();

      await this.db.setItem('defaultEmojis', this.defaultEmojis);
    }
  }

  async loadCustomEmojis() {
    this.customEmojisVersion = await this.db.getItem('customEmojisVersion');
    this.customEmojis = await this.db.getItem('customEmojis');

    if (
      !this.customEmojis ||
      !this.customEmojisVersion ||
      this.customEmojisVersion !== (this.authService.tenant.customEmojisVersion ?? 1)
    ) {
      const customEmojisConfig: {
        version: number;
        emojis: Record<string, string>;
      } = await this.apiService.getPromise('/me/tenant/custom-emojis');

      this.customEmojis = Object.entries(customEmojisConfig.emojis)
        .filter(([key, value]) => value.startsWith('https://'))
        .map(([key, value]) => ({
          id: key,
          name: key,
          keywords: ['custom'],
          skins: [
            {
              src: value,
            },
          ],
        }));

      await this.db.setItem('customEmojis', this.customEmojis);
      await this.db.setItem('customEmojisVersion', customEmojisConfig.version);
    }
  }

  async loadEmojis() {
    if (this.isLoaded) {
      return;
    }

    await Promise.all([this.loadDefaultEmojis(), this.loadCustomEmojis()]);

    await init({
      data: async () => {
        return this.defaultEmojis;
      },
      custom: [
        {
          id: 'custom',
          name: 'Custom',
          emojis: this.customEmojis,
        },
      ],
    });

    this.isLoaded = true; // Set isLoaded here to prevent multiple loads
  }

  async getEmojiList() {
    await this.loadEmojis();
    return Data?.emojis;
  }

  getReactionEmoji(emojiName: string) {
    const [name, skinTone] = emojiName.split('::');
    const skinToneIndexStr = skinTone ? skinTone.replace('skin-tone-', '') : '0';
    const skinToneIndex = parseInt(skinToneIndexStr, 10);

    const emoji = Data.emojis[name]?.skins?.[skinToneIndex];

    return emoji?.native ?? emoji?.src ?? `:${name}:`;
  }
}
